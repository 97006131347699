import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import { BootstrapVue } from "bootstrap-vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import vueHeadful from 'vue-headful';
import VueMobileDetection from 'vue-mobile-detection'


import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

import "bootstrap/dist/css/bootstrap.css";
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import VueSmoothScroll from 'vue2-smooth-scroll'
Vue.use(VueSmoothScroll)

library.add(faUserSecret);

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(VueMobileDetection)
Vue.component("fa", FontAwesomeIcon);
Vue.component('vue-headful', vueHeadful);
Vue.component('Navbar', Navbar);
Vue.component('Footer', Footer);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
