<template navbar>
  <div id="navbar">
    <nav
      :class="
        'navbar transparent mobileNav ' +
        (scroll > 150 ? 'navbar--scrolled' : '')
      "
    >
      <p class="navbar-brand"></p>
      <div class="justify-content-right">
        <router-link
          to="/"
          class="a text-white btn btn-info-success my-2 my-sm-0 mobileComponent"
          >Home</router-link
        >
        <router-link
          to="/services"
          class="a text-white btn btn-info-success my-2 my-sm-0 mobileComponent"
          >Services</router-link
        >
        <router-link
          to="/recommendations"
          class="a text-white btn btn-info-success my-2 my-sm-0 mobileComponent"
          >Recommendations</router-link
        >
        <a
          href="mailto:barry@hairdesignbybarry.ch"
          class="a text-white btn btn-info-success my-2 my-sm-0 mobileElement"
        >
          <i class="far fa-envelope mobileContactButton"></i>
        </a>
      </div>
    </nav>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        scroll: 0,
      };
    },
    methods: {
      scrollFn() {
        this.scroll = window.pageYOffset;
      },
    },
    mounted() {
      this.scroll = window.pageYOffset;
    },
    created() {
      window.addEventListener("scroll", this.scrollFn);
    },
    destroyed() {
      window.removeEventListener("scroll", this.scrollFn);
    },
  };
</script>
<style>
  @media only screen and (max-width: 768px) {
    .mobileNav {
      position: relative !important;
      background: #141414 !important;
    }
    .mobileElement {
      margin-left: -3% !important;
    }
    .mobileContactButton {
      color: white;
    }
    .mobileComponent {
      font-size: 16px !important;
      margin-left: -3% !important;
    }
  }
  @media (min-width: 768px) {
    .mobileNav {
      position: fixed !important;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1030;
    }
    .mobileElement:after {
      /* display:none !important; */
      content: "Contact";
    }
    .mobileContactButton {
      display: none;
    }
  }
  .text-white {
    color: white;
  }
  .a {
    color: white;
    transition: all 0.3s;
    text-decoration: none;
    border: #fff hidden 1px;
  }
  .a:hover {
    color: #ffffff;
    border: #fff solid 1px;
    transition: all 0.3s;
    text-decoration: none;
  }
  .a:focus {
    color: #ffffff;
    text-decoration: none;
    border: #fff solid 1px;
  }
  .logo {
    color: white;
    transition: all 0.3s;
    text-decoration: none;
  }
  .logo:hover {
    color: #1f54d8;
    transition: all 0.3s;
    text-decoration: none;
  }
  .logo:focus {
    color: #ffffff;
    text-decoration: none;
  }
  .transparent {
    background: transparent;
    transition: 0.4s;
  }
  .navbar.navbar--scrolled {
    background: #141414;
    color: black;
    transition: 0.2s;
  }
</style>
