<template footer>
  <div>
    <!-- Site footer -->
    <footer class="site-footer" id="contact">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <h6>Hair Design By Barry</h6>
            <p class="text-justify">
              If you'd like to get in touch, send me an email at<br />
              <a
                class="text-white"
                href="mailto:barry@hairdesignbybarry.ch"
                target="_blank"
                >barry@hairdesignbybarry.ch</a
              >.
            </p>
          </div>

          <div class="col-xs-6 col-md-5 justify-content-right">
            <h6>Address</h6>
            <ul class="footer-links">
              <li>Falknerstrasse 12, 4001 Basel, Switzerland</li>
            </ul>
            <iframe
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?q=Hair%20Design%20By%20Barry&amp;t=m&amp;z=18&amp;output=embed&amp;iwloc=near"
              title="Hair Design By Barry"
              aria-label="Hair Design By Barry"
              style="width: 100%"
            ></iframe>
          </div>
        </div>
        <hr />
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-sm-6 col-xs-12">
            <p class="copyright-text">
              &copy;{{ copyright }} Barry J. Cook - All Rights Reserved<br />Website
              developed &amp; maintained by:
              <a
                style="color: #0196ce"
                href="https://tobyroyal.codes/?ref=barrycook"
                target="_blank"
                >Toby Royal (tobyroyal.codes)</a
              >.
            </p>
          </div>

          <div class="col-md-4 col-sm-6 col-xs-12">
            <ul class="social-icons">
              <li>
                <a class="facebook" href="#"><i class="fab fa-facebook"></i></a>
              </li>
              <li>
                <a class="linkedin" href="#" @click.native="$scrollToTop"
                  ><i class="fas fa-arrow-up"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
  export default {
    methods: {
      scrollToTop() {
        window.scrollTo({ y: 0, behaviour: "smooth" });
      },
    },
    data() {
      return {
        copyright: new Date().getFullYear(),
      };
    },
  };
</script>
<style>
  .site-footer {
    background-color: #26272b;
    padding: 45px 0 20px;
    font-size: 15px;
    line-height: 24px;
    color: #737373;
  }
  .site-footer hr {
    border-top-color: #bbb;
    opacity: 0.5;
  }
  .site-footer hr.small {
    margin: 20px 0;
  }
  .site-footer h6 {
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 5px;
    letter-spacing: 2px;
  }
  .site-footer a {
    color: #737373;
  }
  .site-footer a:hover {
    color: #3366cc;
    text-decoration: none;
  }
  .footer-links {
    padding-left: 0;
    list-style: none;
  }
  .footer-links li {
    display: block;
  }
  .footer-links a {
    color: #737373;
  }
  .footer-links a:active,
  .footer-links a:focus,
  .footer-links a:hover {
    color: #3366cc;
    text-decoration: none;
  }
  .footer-links.inline li {
    display: inline-block;
  }
  .site-footer .social-icons {
    text-align: right;
  }
  .site-footer .social-icons a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin-left: 6px;
    margin-right: 0;
    border-radius: 100%;
    background-color: #33353d;
  }
  .copyright-text {
    margin: 0;
  }
  @media (max-width: 991px) {
    .site-footer [class^="col-"] {
      margin-bottom: 30px;
    }
  }
  @media (max-width: 767px) {
    .site-footer {
      padding-bottom: 0;
    }
    .site-footer .copyright-text,
    .site-footer .social-icons {
      text-align: center;
    }
  }
  .social-icons {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  .social-icons li {
    display: inline-block;
    margin-bottom: 4px;
  }
  .social-icons li.title {
    margin-right: 15px;
    text-transform: uppercase;
    color: #96a2b2;
    font-weight: 700;
    font-size: 13px;
  }
  .social-icons a {
    background-color: #eceeef;
    color: #818a91;
    font-size: 16px;
    display: inline-block;
    line-height: 44px;
    width: 44px;
    height: 44px;
    text-align: center;
    margin-right: 8px;
    border-radius: 100%;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }
  .social-icons a:active,
  .social-icons a:focus,
  .social-icons a:hover {
    color: #fff;
    background-color: #29aafe;
  }
  .social-icons.size-sm a {
    line-height: 34px;
    height: 34px;
    width: 34px;
    font-size: 14px;
  }
  .social-icons a.facebook:hover {
    background-color: #3b5998;
  }
  .social-icons a.twitter:hover {
    background-color: #00aced;
  }
  .social-icons a.linkedin:hover {
    background-color: #007bb6;
  }
  .social-icons a.dribbble:hover {
    background-color: #ea4c89;
  }
  @media (max-width: 767px) {
    .social-icons li.title {
      display: block;
      margin-right: 0;
      font-weight: 600;
    }
  }
</style>
